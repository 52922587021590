import { Link } from 'react-router-dom';
import "./CopyrightBox.scss"

const CopyrightBox = () => {

    return (
        <section className='copyright'>
            <Link to="#" className="footer__copyright">© ООО ЕКОМСЕЛЛЕР, 2022 г. Все права защищены.</Link>
            <Link to="#" className="footer__link">Пользовательское соглашение</Link>
            <Link to="#" className="footer__link">Политика оплаты и возврата</Link>
            <Link to="#" className="footer__link">Политика конфиденциальности</Link>
            <Link to="#" className="footer__link country" style={{ 'border': 'none' }}>Россия</Link>
        </section>
    )
}

export { CopyrightBox }
import Footer from './components/Footer';
import Cookies from 'universal-cookie'
import { Main } from './pages/Main/Main';
import { Article } from './components/Article/Article';
import { Page404 } from './pages/Page404/Page404'
import { HeaderMain } from './components/HeaderMain/HeaderMain';
import { useState, useEffect, createContext } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import './index.scss'



export const BASE_ROUTE = 'articles'

export const LANGS = {
  TR: 'tr',
  EN: 'en',
  RU: 'ru'
}

function App() {

  const path = useParams()
  const navigate = useNavigate()
  const [showNotificationBox, setShowNotificationBox] = useState(false)
  const [activeLang, setActiveLang] = useState(() => localStorage.getItem('lang') ?? LANGS.EN)

  const [paths, setPaths] = useState([])
  const [pathsWebName, setPathsWebName] = useState([])
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [currentArticle, setCurrentArticle] = useState({})
  const [tags, setTags] = useState([])


  const setLanguage = lang => {
    let langForHtml
    switch (lang) {
      case LANGS.EN:
        langForHtml = 'en'
        break;
      case LANGS.TR:
        langForHtml = 'tr'
        break;
      case LANGS.RU:
        langForHtml = 'ru'
        break;
      default:
        throw new Error('unknown language')
    }
    document.documentElement.setAttribute('lang', langForHtml)
    localStorage.setItem('lang', lang)
    setActiveLang(lang)
  }

  const setNavigation = async () => {
    const cookies = new Cookies()

    const auth_token = await cookies.get('auth')

    if (auth_token) {
      if (path['*'] === '') {
        navigate(`/articles`)
      }
    } else {
      navigate('/auth')
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [navigate]);

  useEffect(() => {
    setNavigation()
  }, [])

  let pathBread = ''

  return (
    <>
      <main className='main'>
        <div className='content'>
          <NotificationContext.Provider value={{
            showNotificationBox,
            setShowNotificationBox
          }}>
            <HeaderMain activeLang={activeLang} setActiveLang={setLanguage} />
            <Routes>

              <Route path='/*' element={<Main
                activeLang={activeLang} 
                pathBread={pathBread}
                currentArticle={currentArticle}
                setCurrentArticle={setCurrentArticle}
                pathsWebName={pathsWebName}
                setPathsWebName={e => setPathsWebName(e)}
                description={description}
                setDescription={e => setDescription(e)}
                setPaths={e => setPaths(e)}
                paths={paths}
                title={title}
                setTitle={e => setTitle(e)}
              />} />
              <Route path='/*' element={<Page404 />} />
            </Routes>
          </NotificationContext.Provider>
        </div>
      </main>
      <Footer activeLang={activeLang} />
    </>
  );
}
export const NotificationContext = createContext(false)

export default App;

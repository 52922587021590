import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {AuthorizationPage} from './pages/AuthorizationPage/AuthorizationPage'
import { RegistrationPageAdmin } from './components/RegistartionPageAdmin/RegistrationPageAdmin';
import  {AcceptMail} from'./components/AcceptMail/AcceptMail'
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Routes>
        <Route path='/auth/*' element={< AuthorizationPage />} />
        <Route path='/accept-mail/:id' element={<AcceptMail />} /> 
        
        <Route path='/*' element={< App />} />
      </Routes>
    </BrowserRouter>
  </HelmetProvider>
);


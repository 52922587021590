import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Mail.scss'

const Mail = ({title, text, setIsModal}) => {

    return (
        <div className='blackout'>  
            <div className='modal-mail'>
                <button className='btn-close'
                onClick={(e) => setIsModal(false)}
                ></button>
                <h2 className='title'>{title}</h2>
                <p className='text_grey-blue'>{text}</p>
                <button  onClick={(e) => setIsModal(false)}  className='btn-done'></button>
            </div>
       </div>
    )
}

export { Mail }
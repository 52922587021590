import { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { KonstantionAuthHttps, KonstantionAuthHttp } from '../../fetchUrls'
import headers from '../../common/headers'
import Cookies from 'universal-cookie'

const AcceptMail = () => {
    const params = useParams()
    const navigate = useNavigate()
    const cookies = new Cookies()

    const device_info = window.navigator.userAgent


    useEffect(() => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantionAuthHttps}/ecom-redactor/verify-email`
        } else {
            url = `${KonstantionAuthHttp}/ecom-redactor/verify-email`
        }

        const body = JSON.stringify({
            token: params.id,
            device_info
        })

        fetch(url,
            {
                body,
                method: 'POST',
                headers: { 'Content-type': 'application/json' }
            })
            .then(res => res.json())
            .then(async json => {
                const {
                     access_token,
                    refresh_token,
                    login
                } = json

                let d = new Date()
                let d2 = new Date()
                d.setTime(d.getTime() + (1000 * 60 * 60 * 24))
                d2.setTime(d.getTime() + (1000 * 60 * 60 * 24 * 7))

                if (login) {
                    localStorage.setItem("email", login)
                }
                if (access_token) {
                    await cookies.set('auth', access_token, { expires: d })
                    console.log(access_token)
                    localStorage.setItem('auth', access_token)
                }
                if (refresh_token) {
                    await cookies.set('refresh', refresh_token, { expires: d2 })
                    localStorage.setItem('refresh', refresh_token)
                }

                if(login && access_token && refresh_token) {
                    window.location.reload() 
                    window.location.assign(`/articles`)
                } else {
                   window.location.assign(`/auth`)
                }
            })
            .catch(err => {
                console.log(err)
                window.location.assign(`/auth`)
            })
    }, [])


    return (
        <div
            style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                fontSize: '20px'
            }}
        >
            <span>Почта подтверждена, спасибо!</span>
            <Link to={'/main'} style={{ color: 'blue' }}>Перейти на главную страницу</Link>
        </div>
    )
}

export { AcceptMail }
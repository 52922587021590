import * as React from 'react';
import TextField from '@mui/material/TextField';
import './InputDinamycPlaceholder.scss'

const InputDinamycPlaceholder = ({ classname, placeholder, value, type = "text", onChange, passwordTooltip = false, err, textErr }) => {
    return (
        <div className='input-box'>
            <TextField
                className={`${classname} ${passwordTooltip ? "tooltip-password" : ''} ${err ? 'input-err' : ''}`}
                label={placeholder}
                variant="filled"
                value={value}
                type={type}
                onChange={(e) => onChange(e)}
                spellCheck='false'
            />
            {err && <div className='input-box__err'>
                <button className='btn__err'></button>
                <p className='text_red'>{textErr}</p>
            </div>}
        </div>
    );
}

export { InputDinamycPlaceholder }
import { Link } from 'react-router-dom';
import {  ReactComponent as Logo} from './img/Logo.svg'
import "./Logo.scss"

const LogoBox = () => {

    return (
        <div className='logo'>
            <Logo />
            <Link to="#" className='logo__link' >Ecomlab</Link>           
        </div>
    )
}

export { LogoBox }
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { KonstantionAuthHttps, KonstantionAuthHttp, VladimirHttps, VladimirHttp } from '../../fetchUrls';
import Cookies from 'universal-cookie';
import ExternalAuthentication from '../ExternalAuthentication/ExternalAuthentication'
import { validationEmail } from '../../common/validationFunc';
import "./AuthorizationBox.scss"
import BlueCheckbox from '../../common/components/BlueCheckbox/BlueCheckbox'
import { InputDinamycPlaceholder } from '../InputDinamycPlaceholder/InputDinamycPlaceholder';
import { PasswordRecoveryPage } from '../../pages/PasswordRecoveryPage/PasswordRecoveryPage';
import { ModalErr } from '../Modal/ModalErr/ModalErr';

const AuthorizationBox = ({ setLoading, admin = false, requestError, setRequestError }) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [inValid, setInvalid] = useState(true)
    const [rememberMe, setRememberMe] = useState(false)
    const [disabledBtn, setdisabledBtn] = useState(false)
    const [hidePassword, setHidePassword] = useState(false)
    const [displayModal, setDisplayModal] = useState(false)

    const params = useParams()
    const [isModalErr, setIsModalErr] = useState(false)

    const [isModal, setIsModal] = useState(false)
    const [showModal, setSShowModal] = useState(false)


    const handlerSetEmail = (e) => {
        setInvalid(false)
        setEmail(e.target.value)
    }

    const handlerSetPassword = (e) => {
        setInvalid(false)
        setPassword(e.target.value)
    }

    const handlerSetRememberMe = (e) => {
        setRememberMe(e.target.checked)
    }

    const handlerHidePassword = (e) => {
        setInvalid(false)
        setHidePassword(!hidePassword)
    }

    const activeTypeInput = hidePassword ? "text" : "password"


    let formBig = [email, password, rememberMe]

    useEffect(() => {
        const regexEmail = /^((([0-9A-Za-z]{1}[-0-9A-z\.]{0,30}[0-9A-Za-z]?)|([0-9А-Яа-я]{1}[-0-9А-я\.]{0,30}[0-9А-Яа-я]?))@([-A-Za-z]{1,}\.){1,}[-A-Za-z]{2,})$/;
        //хотя бы одно число, буква латинская в нижнем регистре, латинская буква в верхнем регистре
        const regexPassword = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/


        if (validationEmail(email) && (regexPassword.test(password))) {
            setdisabledBtn(true)
            setRequestError(false)
            setInvalid(false)
        } else {
            setdisabledBtn(false)
        }
    }, [email, password])

    const verifyEmail = (e) => {
        // setLoading(true)
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantionAuthHttps}/ecom-redactor/email-verify-token`
        } else {
            url = `${KonstantionAuthHttp}/ecom-redactor/email-verify-token`
        }

        const headers = { 'Content-type': 'application/json' }

        const body = JSON.stringify({ email: email })

        fetch(url, { method: 'POST', body, headers })
            .then(res => res.json())
            .then(async (json) => {
                setDisplayModal(true)
                setTimeout(() => {
                    setDisplayModal(false)
                }, 5000)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => setLoading(false))
    }


    const handlerSubmit = (e) => {
        // setLoading(true)
        const cookies = new Cookies()
        e.preventDefault()
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantionAuthHttps}/ecom-redactor/login`
        } else {
            url = `${KonstantionAuthHttp}/ecom-redactor/login`
        }

        const device_info = window.navigator.userAgent

        let body = []

        const bodyData = {
            username: email,
            password: password,
            device_info
        }

        for (const key in bodyData) {
            const encodedKey = encodeURIComponent(key);
            const encodedValue = encodeURIComponent(bodyData[key]);
            body.push(encodedKey + "=" + encodedValue);
        }

        body = body.join('&')
        const headers = { 'Content-type': 'application/x-www-form-urlencoded' }


        fetch(url, { method: 'POST', body, headers })
            .then(res => res.json())
            .then(async (json) => {

                if (json?.detail == 'LOGIN_BAD_CREDENTIALS') {
                    setInvalid(true)
                    setRequestError(true)
                    return
                } else if (json?.detail == 'LOGIN_USER_NOT_VERIFIED') {
                    verifyEmail()
                    setInvalid(true)
                    return
                } else if (json?.detail == "Not Found") {
                    setIsModalErr(true)
                } else {
                    const {
                        client_id,
                        access_token,
                        refresh_token,
                        not_one_account
                    } = json

                    let d = new Date()
                    let d2 = new Date()
                    d.setTime(d.getTime() + (1000 * 60 * 60 * 24))
                    d2.setTime(d.getTime() + (1000 * 60 * 60 * 24 * 7))

                    if (email) {
                        localStorage.setItem("email", email)
                    }
                    if (client_id) {
                        localStorage.setItem("client_id", client_id)
                    }
                    if (not_one_account !== 'undefined') {
                        localStorage.setItem('not_one_account', not_one_account)
                    }
                    if (access_token) {
                        await cookies.set('auth', access_token, { expires: d })
                        console.log(access_token)
                    }
                    if (refresh_token) {
                        await cookies.set('refresh', refresh_token, { expires: d2 })
                    }

                    setLoading(false)

                    window.location.assign('/articles')

                }
            })
            .catch(err => {
                console.log(err)
                setRequestError(true)
                setIsModalErr(true)
            })
            .finally(() => setLoading(false))
    }



    return (
        <>
            {isModal && <PasswordRecoveryPage setIsModal={(e) => setIsModal(e)} />}

            {isModalErr && <ModalErr isModal={isModalErr} setIsModal={(e) => setIsModalErr(e)} />}

            <div className='autorization' onSubmit={e => e.preventDefault()} >
                <div style={{ position: 'absolute', width: '100vw', height: '100vh', zIndex: 10, backgroundColor: 'rgba(0,0,0,.3)', left: 0, right: 0, top: 0, bottom: 0, display: displayModal ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: 'white', width: '50vw', height: '30vh', borderRadius: '1em', padding: '1.5em 1em', display: 'flex', alignItems: 'center' }}>
                        <h2 style={{ fontSize: 50, textAlign: 'center', position: 'relative' }}>Подтвердите свою почту,
                            перейдя по ссылке в письме,
                            которое мы Вам отправили.</h2>
                    </div>
                </div>
                <h2 className='title'>Войти</h2>


                <div className='input-with-btn'>
                    <InputDinamycPlaceholder
                        classname='autorization__input-field'
                        placeholder='Введите адрес электронной почты'
                        value={email}
                        type="e-mail"
                        onChange={(e) => handlerSetEmail(e)}
                    />
                </div>

                <div className='input-with-btn'>
                    <InputDinamycPlaceholder
                        classname='autorization__input-field'
                        placeholder='Введите пароль'
                        value={password}
                        onChange={e => handlerSetPassword(e)}
                        type={activeTypeInput}
                    />
                    <button type="button" onClick={() => handlerHidePassword()} className={hidePassword ? 'hide' : 'show'}></button>
                </div>

                <div className='checkbox__box'>
                    {
                        requestError &&
                        <span className='text text_red'>Неверный логин или пароль</span>
                    }

                    <a to="#" className='text text_blue'
                        onClick={(e) => {
                            e.preventDefault()
                            setIsModal(true)
                        }}
                    >Забыли пароль?</a>
                </div>

                <div className='bottom-box-registration'>
                    <button disabled={!disabledBtn} className='btn__blue' onClick={e => handlerSubmit(e)}>Войти</button>
                    {
                        admin ? < Link to="/signup" className='link-box-btn'>
                            < button className='btn__blue'>Регистрация</button>
                        </Link>
                            : null
                    }

                </div>
            </div >
        </>
    )
}

export { AuthorizationBox }
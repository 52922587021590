// Vladimir auth/signup/reset-pass/

export const VladimirHttps = 'https://apps1.ecomru.ru:4432'
export const VladimirHttp = 'https://apps1.ecomru.ru:4432' // 8082

export const VladimirRedactorHttps = 'https://apps2.ecomru.ru:4466'

export const VladimirModalHttps = 'https://apps0.ecomru.ru:4435'
export const VladimirModalHttp = 'https://apps0.ecomru.ru:4435' // 8085

// Oleg main/

export const OlegHttps = 'https://apps1.ecomru.ru:4450'
export const OlegHttp = 'https://apps1.ecomru.ru:4450' // 8090

export const OlegOrdersHttps = 'https://apps2.ecomru.ru:4443'
export const OlegOrdersHttp = 'https://apps2.ecomru.ru:4443'

export const OlegPaymentHttps = 'https://apps0.ecomru.ru:4444'
export const OlegPaymentHttp = 'https://apps0.ecomru.ru:4444' //8094

// Konstantin main/

export const KonstantinHttps = 'https://apps1.ecomru.ru:4434'
export const KonstantinHttp = 'https://apps1.ecomru.ru:4434' //8083

export const KonstantionAuthHttps = 'https://apps0.ecomru.ru:4430'
export const KonstantionAuthHttp= 'https://apps0.ecomru.ru:4430' //8083

// Artem improveLandings

export const ArtemHttps = 'https://apps1.ecomru.ru:4437'
export const ArtemHttp = 'https://apps1.ecomru.ru:4437' // 8086

export const ArtemVideoHttps = `https://dl.ecomru.ru:4443`
export const ArtemVideoHttp = `https://dl.ecomru.ru:4443` //8081

export const ArtemPhotoHttps = 'https://dl.ecomru.ru:4444' //http - 8083

export const ArtemTransferHttps = 'https://dl.ecomru.ru:5555'
export const ArtemTransferHttp = 'https://dl.ecomru.ru:5555' // 5050

export const ArtemNotificationsHttps = 'https://dl.ecomru.ru:4445'
export const ArtemNotificationsHttp = 'https://dl.ecomru.ru:4445' // 8084

// Valeriya bundles

export const ValeriyaHttps = 'https://apps1.ecomru.ru:4435'
export const ValeriyaHttp = 'https://apps1.ecomru.ru:4435' // 8084

export const ValeriyaArticlesHttps = 'https://apps2.ecomru.ru:4460'

// Andrey parselinks

export const AndreyHttps = 'https://appsche.ecomru.ru:4433'
export const AndreyHttp = 'https://appsche.ecomru.ru:4433' // 8080

// Sergey performance marketing
export const SergeyYandexHttps = 'https://apps1.ecomru.ru:4440'
export const SergeyOzonPerfHttps = 'https://apps1.ecomru.ru:4441'

// Ravshan articles
export const RavshanArticlesHttps = 'https://apps0.ecomru.ru:4447'
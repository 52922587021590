//Sidebar

export const analitics = 'https://dl.ecomru.ru/svg-storage/Analitics (2).svg'
export const auto = 'https://dl.ecomru.ru/svg-storage/Auto.svg'
export const basket = 'https://dl.ecomru.ru/svg-storage/Basket.svg'
export const calendar = 'https://dl.ecomru.ru/svg-storage/Calendar (2).svg'
export const cases = 'https://dl.ecomru.ru/svg-storage/Cases.svg'
export const drop = 'https://dl.ecomru.ru/svg-storage/Drop.svg'
export const education = 'https://dl.ecomru.ru/svg-storage/Education.svg'
export const experiment = 'https://dl.ecomru.ru/svg-storage/Experiment.svg'
export const icon_edit = 'https://dl.ecomru.ru/svg-storage/IconEdit.svg'
export const intagration = 'https://dl.ecomru.ru/svg-storage/Intagration (2).svg'
export const marketing = 'https://dl.ecomru.ru/svg-storage/Marketing (2).svg'
export const perfomance = 'https://dl.ecomru.ru/svg-storage/Perfomance.svg'
export const social = 'https://dl.ecomru.ru/svg-storage/Social.svg'
export const tasks = 'https://dl.ecomru.ru/svg-storage/Task (2).svg'
export const tools = 'https://dl.ecomru.ru/svg-storage/Tools (2).svg'
export const competitors = 'https://dl.ecomru.ru/svg-storage/competitors.svg'

// Date Picker
export const calendar_date_picker = 'https://dl.ecomru.ru/svg-storage/calendar.svg'

// Table

export const filter_table = 'https://dl.ecomru.ru/svg-storage/icon-filter.svg'
export const table_min_green = 'https://dl.ecomru.ru/svg-storage/icon-green (3).svg'
export const filter_string = 'https://dl.ecomru.ru/svg-storage/icon-string.svg'

export const glass = 'https://dl.ecomru.ru/svg-storage/glass.svg'
export const banner_table = 'https://dl.ecomru.ru/svg-storage/table-banner.svg'
export const sort_table = 'https://dl.ecomru.ru/svg-storage/sort.svg'



// Chart 

export const charts_min_violet = 'https://dl.ecomru.ru/svg-storage/icon-violet.svg'

export const search_grey = 'https://dl.ecomru.ru/svg-storage/search-grey.svg'

// Info-box-side
export const info_blue = 'https://dl.ecomru.ru/svg-storage/icon-info.svg'

export const bulb = 'https://dl.ecomru.ru/svg-storage/bulb.svg'

// Logo

export const logo = 'https://dl.ecomru.ru/svg-storage/logo (4).svg'

// Counter

export const drop_grey = 'https://dl.ecomru.ru/svg-storage/drop-grey-bottom.svg'
export const download = 'https://dl.ecomru.ru/svg-storage/download(2).svg'

// MetricsItem 

export const dynamicsUp = 'https://dl.ecomru.ru/svg-storage/arrow-green.svg'
export const dynamicsDown = 'https://dl.ecomru.ru/svg-storage/arrow-red.svg'
export const dynamicsNone = 'https://dl.ecomru.ru/svg-storage/arrow-grey.svg'

// TaskList

export const filter_icon = 'https://dl.ecomru.ru/svg-storage/filter.svg'
export const user_light_grey = 'https://dl.ecomru.ru/svg-storage/user.svg'

// TaskItem

export const time = 'https://dl.ecomru.ru/svg-storage/time.svg'
export const flag = 'https://dl.ecomru.ru/svg-storage/flag.svg'
export const points = 'https://dl.ecomru.ru/svg-storage/points.svg'

// ServiceAuth

export const banner_loader = 'https://dl.ecomru.ru/svg-storage/banner-loader.svg'

// ChatAdmin

export const user_violet = 'https://dl.ecomru.ru/svg-storage/user-violet.svg'
export const user_green = 'https://dl.ecomru.ru/svg-storage/user-green.svg'
export const support_icon = 'https://dl.ecomru.ru/svg-storage/support-icon.svg'
export const user_icon = 'https://dl.ecomru.ru/svg-storage/user-icon.svg'

// AddBalanceModal 

export const icon_add_balance = 'https://dl.ecomru.ru/svg-storage/balance-icon.svg'


// AddProduct

export const icon_done = 'https://dl.ecomru.ru/svg-storage/done.svg'
export const icon_done_active = 'https://dl.ecomru.ru/svg-storage/done-active.svg'

// Bundles

export const icon_bitrix = 'https://dl.ecomru.ru/svg-storage/Bitrix.svg'
export const icon_google = 'https://dl.ecomru.ru/svg-storage/Google.svg'
export const icon_facebook = 'https://dl.ecomru.ru/svg-storage/facebook.svg'


// OfferAndContract

export const icon_contract = 'https://dl.ecomru.ru/svg-storage/doc1.svg'
export const icon_document = 'https://dl.ecomru.ru/svg-storage/doc2.svg'
export const icon_act = 'https://dl.ecomru.ru/svg-storage/doc3.svg'

// Marketplace icon

export const icon_ozon_square_min = 'https://dl.ecomru.ru/svg-storage/Ozon (4).svg'
export const icon_yandex_square_min = 'https://dl.ecomru.ru/svg-storage/yandex (3).svg'


// button

export const btn_delete = 'https://dl.ecomru.ru/svg-storage/delete (2).svg'
export const btn_edit = 'https://dl.ecomru.ru/svg-storage/edit.svg'


// OrderDetailedAnalytics

export const banner_analitics = 'https://dl.ecomru.ru/svg-storage/banner (2).svg'

// LandingIntegration

export const popular_solutions = 'https://dl.ecomru.ru/svg-storage/popular-solutions.svg'
export const marketplace_group = 'https://dl.ecomru.ru/svg-storage/marketplace-group.svg'
export const application_examples = 'https://dl.ecomru.ru/svg-storage/application-examples.svg'
export const integration_img = 'https://dl.ecomru.ru/svg-storage/integration.svg'


// LandingPerformance

export const logo_group = 'https://dl.ecomru.ru/svg-storage/logo-group.svg'
export const connect_img = 'https://dl.ecomru.ru/svg-storage/connect.svg'
export const charts_blur = 'https://dl.ecomru.ru/svg-storage/charts-blur.svg'
export const stairs_img = 'https://dl.ecomru.ru/svg-storage/stairs.svg'

// LandingProducts

export const content_img = 'https://dl.ecomru.ru/svg-storage/content.svg'
export const pen_img = 'https://dl.ecomru.ru/svg-storage/pen.svg'
export const monitor_img = 'https://dl.ecomru.ru/svg-storage/monitor.svg'

// Landing

export const change_1 = 'https://dl.ecomru.ru/svg-storage/change-1.svg'
export const change_2 = 'https://dl.ecomru.ru/svg-storage/change-2.svg'
export const change_3 = 'https://dl.ecomru.ru/svg-storage/change-3.svg'
export const change_4 = 'https://dl.ecomru.ru/svg-storage/change-4.svg'
export const logo_group_img = 'https://dl.ecomru.ru/svg-storage/LogoGroup.svg'


// SearchByArticle

export const star = 'https://dl.ecomru.ru/svg-storage/star.svg'
export const product_img = 'https://dl.ecomru.ru/svg-storage/img-poduct.svg'

// RegistrationPageAdmin

export const banner_registration_admin = 'https://dl.ecomru.ru/svg-storage/banner (4).svg'
export const banner_registration_executor = 'https://dl.ecomru.ru/svg-storage/banner-executor.svg'

// QuestionnaireAfterRegistration

export const user_violet_medium = 'https://dl.ecomru.ru/svg-storage/user (4).svg'

// AddVideoLanding

export const rich_video = 'https://dl.ecomru.ru/svg-storage/rich-video.svg'

// AutoPhotoLanding

export const rich_photo = 'https://dl.ecomru.ru/svg-storage/auto-photo.svg'

// AutoRichLanding

export const rich_auto = 'https://dl.ecomru.ru/svg-storage/auto-rich.svg'

// DescriptionImprovementLanding

export const rich_description = 'https://dl.ecomru.ru/svg-storage/rich-description.svg'

// ContentProduct

export const content_photo_img = 'https://dl.ecomru.ru/svg-storage/img.svg'

// CreateCampaign

export const insta_round_medium = 'https://dl.ecomru.ru/svg-storage/insta.svg'
export const ozon_round_medium = 'https://dl.ecomru.ru/svg-storage/ozon.svg'
export const wb_round_medium = 'https://dl.ecomru.ru/svg-storage/wb.svg'
export const vk_round_medium = 'https://dl.ecomru.ru/svg-storage/vk.svg'
export const yandex_img_round_medium = 'https://dl.ecomru.ru/svg-storage/yandex.svg'
export const facebook_img_round_medium = 'https://dl.ecomru.ru/svg-storage/facebook.svg'

// ListOfRules

export const ozon_icon_square = 'https://dl.ecomru.ru/svg-storage/ozon.svg'
export const edit_icon_round = 'https://dl.ecomru.ru/svg-storage/edit (2).svg' // битая
export const delete_icon_round = 'https://dl.ecomru.ru/svg-storage/delete.svg'

// ChatAdmin

export const ecom_icon = 'https://dl.ecomru.ru/svg-storage/ecom-icon.svg'

// NotificationMarketplace

export const wb_icon_square = 'https://dl.ecomru.ru/svg-storage/wb (3).svg'

// Notifications

export const message_icon = 'https://dl.ecomru.ru/svg-storage/message.svg'
export const tel_icon = 'https://dl.ecomru.ru/svg-storage/tel.svg'
export const tg_icon = 'https://dl.ecomru.ru/svg-storage/tg (2).svg'
export const whatsapp_icon = 'https://dl.ecomru.ru/svg-storage/WhatsApp.svg'


// ProductItem

export const tel_min_1 = 'https://dl.ecomru.ru/svg-storage/tel-min-1.svg'
export const tel_min_2 = 'https://dl.ecomru.ru/svg-storage/tel-min-2.svg'
export const tel_min_3 = 'https://dl.ecomru.ru/svg-storage/tel-min-3.svg'
export const tel_max = 'https://dl.ecomru.ru/svg-storage/tel-max.svg'
export const apple_icon = 'https://dl.ecomru.ru/svg-storage/apple.svg'
export const star_grey = 'https://dl.ecomru.ru/svg-storage/star-grey.svg'
export const star_yellow = 'https://dl.ecomru.ru/svg-storage/star-yellow.svg'

// SetBalances

export const citrus_img = 'https://dl.ecomru.ru/svg-storage/Citrus.svg'
export const ozon_square = 'https://dl.ecomru.ru/svg-storage/ozon (2).svg'

// Modal

export const done = 'https://dl.ecomru.ru/svg-storage/done.svg'
export const lock = 'https://dl.ecomru.ru/svg-storage/lock.svg'
export const content = 'https://dl.ecomru.ru/svg-storage/content.svg'
export const drop_light_grey = 'https://dl.ecomru.ru/svg-storage/Drop (2).svg'
export const key = 'https://dl.ecomru.ru/svg-storage/key.svg'
export const icon_notification = 'https://dl.ecomru.ru/svg-storage/icon-notification.svg'
export const info_yellow = 'https://dl.ecomru.ru/svg-storage/info (4).svg'



import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonBasic } from '@lk-gtcom/ecomlab-components'
import './ModalError.scss'

const ModalError = ({ headerText, messageText, setShowModal, articleLink, redactorLink }) => {

    const navigate = useNavigate()
    const [ error, setError ] = useState(false)

    return (
        <div className='blackout'>
            <div className='modal-box modal-error'>
                <div className='modal-body'>
                    <div style={{paddingLeft: '40px', paddingRight: '40px', paddingBottom: '52px'}}>
                        <h2 className='title'>{headerText}</h2>
                        <p className='text'>{messageText}</p>
                    </div>

                    <div style={{boxSizing: 'border-box', padding: '.5em .5em 1em', display: 'flex', justifyContent: 'center'}}>
                        <div style={{ display: 'flex', gap: '1em' }}>
                            <ButtonBasic 
                                whiteBlue 
                                text={redactorLink ? 'Открыть в редакторе' : 'Понятно'} 
                                onClick={() => {
                                    if(redactorLink) {
                                        navigate(redactorLink)
                                    } else {
                                        setShowModal(false)
                                    }
                                }} 
                            />
                            {articleLink && <ButtonBasic blue text='Посмотреть на портале' onClick={() => articleLink ? window.open(articleLink) : setError()} />}
                        </div>
                    </div>
                    

                    {/* <img src={done} className='icon_done-round'></img> */}

                    {/* <button className='btn__blue-modal' onClick={() => setShowModal(false)}>X</button> */}
                </div>
            </div>
        </div>
    )
}

export default ModalError
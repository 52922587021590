import { useState, useEffect } from 'react'
import { InputDinamycPlaceholder } from '../../components/InputDinamycPlaceholder/InputDinamycPlaceholder';
import { KonstantionAuthHttps, KonstantionAuthHttp } from '../../fetchUrls';
import { Link, Routes, Route, useNavigate, useParams } from 'react-router-dom'
import { Mail } from '../../components/PasswordRecoveryBox/Mail/Mail'
import Cookies from 'universal-cookie';
import './PasswordRecoveryPage.scss'
import { ModalErr } from '../../components/Modal/ModalErr/ModalErr';

const PasswordRecoveryPage = ({ setIsModal }) => {
    const path = useParams();
    const navigate = useNavigate()
    const [email, setEmail] = useState('')

    useEffect(() => {
        if (path['*'] === '') {
            navigate('email')
        }
    }, [])

    const [inValid, setInvalid] = useState(false)
    const [incorrectEmail, setIncorrectEmail] = useState(false)
    const [activeContent, setActiveContent] = useState(true)
    const [disabledBtn, setdisabledBtn] = useState(false)
    const [isModalErr, setIsModalErr] = useState(false)
    const [isModalMail, setIsModalMail] = useState(true)
    const auth = new Cookies().get('auth')

    const handlerSetEmail = (e) => {
        setIncorrectEmail(false)
        setInvalid(false)
        setEmail(e.target.value)
    }

    useEffect(() => {
        const regexEmail = /^((([0-9A-Za-z]{1}[-0-9A-z\.]{0,30}[0-9A-Za-z]?)|([0-9А-Яа-я]{1}[-0-9А-я\.]{0,30}[0-9А-Яа-я]?))@([-A-Za-z]{1,}\.){1,}[-A-Za-z]{2,})$/

        if (regexEmail.test(email)) {
            setdisabledBtn(true)
        } else {
            setdisabledBtn(false)
        }
    }, [email])

    const handlerSubmit = (e) => {
        if (email.length < 1) {
            setInvalid(true)
        }

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantionAuthHttps}/ecom-redactor/forgot-password`
        } else {
            url = `${KonstantionAuthHttp}/ecom-redactor/forgot-password`
        }

        const body = JSON.stringify({
            email
        })

        fetch(url,
            {
                body,
                method: 'POST',
                headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${auth}` }
            })
            .then((response) => response.json())
            .then(res => res.json())
            .then(async (json) => {
                setActiveContent(true)
                setIsModalMail(true)
            })
            .catch(err => {
                setIsModalErr(true)
                console.log(err)
            })
    }

    return (
        <>
            {isModalErr && <ModalErr isModal={isModalErr} setIsModal={(e) => setIsModalErr(e)} />}
            {activeContent ? <div className='blackout'>
                <div className='password-recovery'>
                    <button className='btn-close' onClick={(e) => setIsModal(false)}></button>
                    <h2 className='title'
                        onClick={(e) => setIsModal(false)}
                    >Восстановление пароля</h2>
                    <div className='input-with-btn'>
                        <InputDinamycPlaceholder
                            onChange={e => handlerSetEmail(e)}
                            type="e-mail"
                            classname='autorization__input-field'
                            placeholder='E-mail'
                            value={email}
                        />
                    </div>
                    <button
                        disabled={!disabledBtn}
                        className='btn__blue'
                        onClick={e => {
                            handlerSubmit()
                            setActiveContent(false)
                        }}
                    >Восстановить пароль</button>
                </div>
            </div>
                :
                <>
                    {

                        < Mail
                            title='Проверьте электронную почту'
                            text={`Мы отправили инструкцию для восстановления пароля на ${email}`}
                            setIsModal={(e) => setIsModal(e)}
                        />
                    }
                </>
            }
        </>
    )
}

export { PasswordRecoveryPage }
import { Link } from 'react-router-dom';
import "./ProfileSettings.scss"

const ProfileSettings = ({onLogOutHandler}) => {

    return (
        <div className='profile-settings'>
            <button className='profile-settings__btn'> <Link to="/settings/connections">Настройки</Link> </button>
            <button className='profile-settings__btn'>Язык</button>
            <button className='profile-settings__btn profile-settings__btn_exit' onClick={e => onLogOutHandler(e)}>Выход</button>
        </div>
    )
}

export { ProfileSettings }
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { KonstantionAuthHttps, KonstantionAuthHttp } from '../../fetchUrls';
import Cookies from 'universal-cookie';
import { InputDinamycPlaceholder } from '../InputDinamycPlaceholder/InputDinamycPlaceholder';
import './ResetPasswordBox.scss'
import { ModalErr } from '../Modal/ModalErr/ModalErr';


const ResetPasswordBox = ({ email }) => {
    const navigate = useNavigate()
    const path = useParams()
    const [password, setPassword] = useState('')
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const [hidePassword, setHidePassword] = useState(true)
    const [inValid, setInvalid] = useState(false)
    const [disabledBtn, setdisabledBtn] = useState(false)
    const [isModalErr, setIsModalErr] = useState(false)
    const [err, setErr] = useState(false)

    const handlerSetPassword = (e) => {
        setInvalid(false)
        setPassword(e.target.value)
        setErr(false)
    }

    const handlerSetRepeatPassword = (e) => {
        setInvalid(false)
        setPasswordRepeat(e.target.value)
        setErr(false)
    }

    const handlerHidePassword = (e) => {
        setHidePassword(!hidePassword)
        setErr(false)
    }


    let form = {
        token: path['*'].split('reset-password/')[1],
        password: password,
        device_info: ''
    }

    useEffect(() => {
        const regexPassword = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/

        if ((regexPassword.test(password)) && (regexPassword.test(passwordRepeat))) {
            setdisabledBtn(true)
        } else {
            setdisabledBtn(false)
        }
    }, [password, passwordRepeat])

    const handlerSubmit = (e) => {
        e.preventDefault()
        const cookies = new Cookies()
        if (password !== passwordRepeat) {
            setInvalid(true)
        } else {
            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${KonstantionAuthHttps}/ecom-redactor/reset-password`
            } else {
                url = `${KonstantionAuthHttp}/ecom-redactor/reset-password`
            }

            fetch(url,
                {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: { 'Content-type': 'application/json', 'accept': 'application/json' }
                })
                .then((response) => response.json())
                .then((data) => {
                    setIsModalErr(true)
                    if (data.detail == "RESET_PASSWORD_BAD_TOKEN") {
                        setErr(true)
                    } else if (data?.detail == "Not Found") {
                        setIsModalErr(true)
                    } else {
                        let d = new Date()
                        let d2 = new Date()

                        d.setTime(d.getTime() + (1000 * 60 * 60 * 24))
                        d2.setTime(d.getTime() + (1000 * 60 * 60 * 24 * 7))

                        const { access_token, refresh_token, client_id, not_one_account, login } = data
                        if (email) {
                            localStorage.setItem("email", email)
                        }
                        if (client_id) {
                            localStorage.setItem("client_id", client_id)
                        }
                        if (not_one_account !== 'undefined') {
                            localStorage.setItem('not_one_account', not_one_account)
                        }
                        if (access_token) {
                            cookies.set('auth', access_token, { expires: d })
                        }
                        if (refresh_token) {
                            cookies.set('refresh', refresh_token, { expires: d2 })
                        }

                        if (login && access_token && refresh_token) {
                            window.location.assign(`/articles`)
                        } else {
                            window.location.assign(`/auth`)
                        }

                    }
                })
                .catch(err => {
                    setIsModalErr(true)
                    console.log(err)
                })
        }
    }

    const activeClassPassword = inValid ? 'autorization__input-field_err' : 'autorization__input-field'
    return (
        <>
            {isModalErr && <ModalErr isModal={isModalErr} setIsModal={(e) => setIsModalErr(e)} />}


            <form className='autorization' onSubmit={e => e.preventDefault()} spellCheck='false'>
                <div className='back-block'>
                    <Link className='text_back' to="/auth">Назад на главную</Link>
                </div>

                <h2 className='title' style={{ marginTop: '40px' }}>Восстановление пароля</h2>
                <div className='input-with-btn'>
                    <InputDinamycPlaceholder
                        onChange={(e) => handlerSetPassword(e)}
                        type={hidePassword ? 'password' : 'text'}
                        classname='autorization__input-field'
                        placeholder='Введите новый пароль'
                        value={password}
                        passwordTooltip
                    />
                    <button type="button" onClick={(e) => handlerHidePassword(e)} className={hidePassword ? 'show' : 'hide'}></button>
                </div>
                <div className='input-with-btn' style={{ marginTop: '20px' }}>
                    <InputDinamycPlaceholder
                        onChange={(e) => handlerSetRepeatPassword(e)}
                        type={hidePassword ? 'password' : 'text'}
                        classname='autorization__input-field'
                        placeholder='Введите пароль еще раз'
                        value={passwordRepeat}
                    />
                    <button type="button" onClick={e => handlerHidePassword(e)} className={hidePassword ? 'show' : 'hide'}></button>
                </div>
                {inValid ? <Link to="#" className='text text_red'>Введеные пароли не совпадают</Link> : null}
               
                {err && <span className='text text_red'>Не удалось сбросить пароль</span>}

                <button
                    onClick={e => handlerSubmit(e)}
                    disabled={!disabledBtn}
                    className='btn__blue'
                >
                    Сохранить пароль
                </button>
            </form>
        </>
    )
}

export { ResetPasswordBox }